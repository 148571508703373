import React, { useEffect, useMemo, useState } from "react";
import { Close, Delete, Edit, Visibility } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  Fade,
  FormControl,
  IconButton,
  InputLabel,
  Modal,
  TextField,
  Typography,
  Select,
  MenuItem,
  createTheme,
  Tooltip,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {
  DataGrid,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import "../../style.css";
import { useNavigate } from "react-router-dom";
import { makeStyles, ThemeProvider } from "@mui/styles";
import "../../Switcher.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  createAdminManageCampaign,
  deleteAdminManageCampaign,
  getAdminManageCampaign,
  updateAdminManageCampaign,
} from "../../redux/actions/adminPortal/adminPortal_manageCampaignAction";
import { getAdminUsersList } from "../../redux/actions/adminPortal_listAction";
const drawerWidth = 240;

// =======modal-popup---->
const style = {
  padding: "20px !Important",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  // backgroundColor: "rgb(9, 56, 134)",
  // border: '2px solid #000',
  boxShadow: 24,
};

// ====table----->

const useStyles = makeStyles({
  borderedGreen: {
    borderLeft: "3px solid green", // Add your border styling here
    boxShadow: "2px -1px 4px -3px #686868",
    margin: "4px 4px 1px 4px !important",
  },
  borderedRed: {
    borderLeft: "3px solid red", // Add your border styling here
    boxShadow: "2px -1px 4px -3px #686868",
    margin: "4px 4px 1px 4px !important",
  },
});
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          "& .MuiDataGrid-row": {
            minHeight: "auto", // Adjust row height to make it more compact
          },
        },
      },
      defaultProps: {
        density:"compact", // Set default density to compact
      },
    },
  },
});

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      <GridToolbarFilterButton />
      {/* <GridToolbarExport /> */}
    </GridToolbarContainer>
  );
}

function Manage_Campaign({ colorThem }) {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setCampaignName("");
    setCampaignId("");
    setDescription("");
    setUserId("");
  };

  // -------------------
  const [buyerOpen, setBuyerOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [userId, setUserId] = useState("");
  const [users, setUsers] = useState([]);
  const [campaignName, setCampaignName] = useState("");
  const [campaignId, setCampaignId] = useState("");
  const [status, setStatus] = useState("");
  const [description, setDescription] = useState("");
  const [response, setResponse] = useState("");
  const navigate = useNavigate();
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const handleAddBuyerOpen = () => setBuyerOpen(true);
  const handleAddBuyerClose = () => setBuyerOpen(false);
  const handleEditCampaignOpen = () => setEdit(true);
  const handleEditCampaignClose = () => {
    setEdit(false);
    setCampaignName("");
    setCampaignId("");
    setDescription("");
    setUserId("");
  };

  useEffect(() => {
    dispatch(getAdminManageCampaign());
    dispatch(getAdminUsersList());
  }, [dispatch, response]);

  useMemo(() => {
    if (state?.getAdminUsersList?.userList) {
      const usersArray = Object.keys(state?.getAdminUsersList?.userList)?.map(
        (key) => ({
          user_id: key,
          username: state?.getAdminUsersList?.userList[key],
        })
      );
      setUsers(usersArray);
    }
  }, [state?.getAdminUsersList?.userList]);
  // ------------->

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "campaignName":
        setCampaignName(value);
        break;
      case "status":
        setStatus(value);
        break;
      case "description":
        setDescription(value);
        break;
      default:
        break;
    }
  };

  const handleEdit = (data) => {
    handleEditCampaignOpen();
    setCampaignName(data?.group_name);
    setCampaignId(data?.campaign_id);
    setStatus(data?.status);
    setDescription(data?.description);
    setUserId(data?.user_id);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const request = {
      user_id: userId,
      group_name: campaignName,
      description: description,
    };
    dispatch(createAdminManageCampaign(request, handleClose, setResponse));
  };
  const handleUpdate = () => {
    const request = {
      user_id: userId,
      group_name: campaignName,
      id: campaignId,
      description: description,
    };
    dispatch(
      updateAdminManageCampaign(request, handleEditCampaignClose, setResponse)
    );
  };

  const handleDelete = (data) =>{
    
//dispatch(deleteAdminManageCampaign(data.user_id))
  }
  const handleView = (data) => {
    navigate("/admin_portal/viewbuyer", { state: { data: data } });
  };

  const columns = [
    {
      field: "group_name",
      headerName: "Campaign Name",
      headerClassName: "custom-header",
      headerAlign: "center",
      flex: 1,
      align: "center",
    },
    {
      field: "user_name",
      headerName: "User Name",
      headerClassName: "custom-header",
      headerAlign: "center",
      flex: 1,
      align: "center",
    },

    {
      field: "description",
      headerName: "Description",
      flex: 1,
      headerClassName: "custom-header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Status",
      type: "number",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "custom-header",
      renderCell: (params) => {
        return (
          <div className="d-flex justify-content-between align-items-center">
            {params.row.status === "True" ? (
              <>
                <div
                  style={{
                    color: "white",
                    background: "green",
                    padding: "7px",
                    borderRadius: "5px",
                    fontSize: "12px",
                    textTransform: "capitalize",
                  }}
                ></div>
              </>
            ) : (
              <>
                <div
                  style={{
                    color: "white",
                    background: "red",
                    padding: "7px",
                    borderRadius: "5px",
                    fontSize: "12px",
                    textTransform: "capitalize",
                  }}
                ></div>
              </>
            )}
          </div>
        );
      },
    },
    {
      field: "view_buyer",
      headerName: "Action",
      flex: 1,
      headerClassName: "custom-header",
      headerAlign: "center",
      align: "center",
      sortable: false,
      width: 140,
      renderCell: (params) => {
        return (
          <div className="d-flex justify-content-between align-items-center">
           <Tooltip title="view" disableInteractive interactive>
            <IconButton onClick={()=>handleView(params.row)}>
              <Visibility
                style={{ cursor: "pointer", color: "grey" }}
              />
            </IconButton>
            </Tooltip>
            <Tooltip title="edit" disableInteractive interactive>
            <IconButton onClick={() => handleEdit(params.row)}>
              <Edit
                index={params.row.id}
                style={{ cursor: "pointer", color: "#42765f" }}
              />
            </IconButton>
            </Tooltip>
            <Tooltip title="delete" disableInteractive interactive>
            <IconButton onClick={() => handleDelete(params.row)}>
              <Delete style={{ cursor: "pointer", color: "red" }} />
            </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const mockDataTeam = useMemo(() => {
    const calculatedRows = [];
    state?.getAdminManageCampaign?.ManageCampaign &&
      state?.getAdminManageCampaign?.ManageCampaign.forEach((item, index) => {
        calculatedRows.push({
          id: index + 1,
          group_name: item?.group_name,
          user_name: item?.user_name,
          campaign_id: item?.id,
          user_id: item?.user_id,
          description: item?.description,
        });
      });
    return calculatedRows;
  }, [state?.getAdminManageCampaign?.ManageCampaign]);

  return (
    <>
      <div className={`App ${colorThem} `}>
        <div className="contant_box">
          <Box
            className="right_sidebox mobile_top_pddng"
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
          >
            {/* ========== */}
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      <div
                        className="cntnt_title"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "end",
                        }}
                      >
                        <div>
                          <h3>Campaign</h3>
                          {/* <p>A ring group is a set of destinations that can be called with a ring strategy. </p> */}
                        </div>
                        {/* ==Add-modal== */}
                        <div>
                          <IconButton
                            className="all_button_clr"
                            onClick={handleOpen}
                          >
                            Add Campaign <AddOutlinedIcon />
                          </IconButton>
                        </div>
                        {/* -----   Add Campaigns Modal Start   ----- */}

                        <Modal
                          aria-labelledby="transition-modal-title"
                          aria-describedby="transition-modal-description"
                          open={open}
                          closeAfterTransition
                          slots={{ backdrop: Backdrop }}
                          slotProps={{
                            backdrop: {
                              timeout: 500,
                            },
                          }}
                        >
                          <Fade in={open}>
                            <Box
                              sx={style}
                              borderRadius={"10px"}
                              textAlign={"center"}
                            >
                              <IconButton
                                onClick={handleClose}
                                sx={{ float: "inline-end" }}
                              >
                                <Close />
                              </IconButton>
                              <br />
                              <Typography
                                id="transition-modal-title"
                                variant="h6"
                                component="h2"
                                color={"#092b5f"}
                                fontSize={"18px"}
                                fontWeight={"600"}
                              >
                                Add Campaign
                              </Typography>
                              <Typography
                                id="transition-modal-description"
                                sx={{ mt: 2 }}
                                fontSize={"16px"}
                                color={"#000"}
                                paddingBottom={"10px"}
                              ></Typography>

                              <form
                                style={{
                                  textAlign: "center",
                                  height: "400px",
                                  overflow: "auto",
                                  paddingTop: "10px",
                                  padding: "20px",
                                }}
                              >
                                <TextField
                                  style={{ width: "100%", margin: "7px 0" }}
                                  type="text"
                                  label="Campaign Name"
                                  variant="outlined"
                                  value={campaignName}
                                  onChange={(e) => {
                                    setCampaignName(e.target.value);
                                  }}
                                />
                                <br />
                                <FormControl
                                  fullWidth
                                  style={{
                                    width: "100%",
                                    margin: "7px 0",
                                  }}
                                >
                                  <InputLabel id="demo-simple-select-label">
                                    User Name
                                  </InputLabel>

                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="User Name"
                                    helperText="Select the language."
                                    style={{ textAlign: "left" }}
                                    value={userId}
                                    onChange={(e) => {
                                      setUserId(e.target.value);
                                    }}
                                  >
                                    {users?.map((item, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={item?.user_id}
                                        >
                                          {item.username}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                                {/* <TextField
style={{width: "100%",
margin:"7px 0"}}
type="text"
label="Extension"
variant="outlined"
/>
<br />
<Typography style={{display:'flex', justifyContent:'space-between', alignItems: 'center'}}>
<FormControl fullWidth style={{ width: "80%", margin:"7px 0" }}>
       <InputLabel id="demo-simple-select-label">User List</InputLabel>
       <Select
         labelId="demo-simple-select-label"
         id="demo-simple-select"
         label="User List"
         helperText="Select the language."
         style={{textAlign:'left'}}
         
       >
         <MenuItem value={10}>1</MenuItem>
         <MenuItem value={20}>2</MenuItem>
         <MenuItem value={30}>3</MenuItem>
       </Select>
     </FormControl> 
     
     <IconButton className="all_button_clr" style={{width: '55px', height: '55px', margin: '0px !important', }} onClick={handleOpen}>
    ADD 
   </IconButton>
   </Typography>
   
   */}
                                <TextField
                                  style={{ width: "100%", margin: "7px 0" }}
                                  type="text"
                                  label="Description"
                                  variant="outlined"
                                  value={description}
                                  onChange={(e) =>
                                    setDescription(e.target.value)
                                  }
                                />

                                <br />

                                <Button
                                  variant="contained"
                                  className="all_button_clr"
                                  color="primary"
                                  sx={{
                                    fontSize: "16px !impotant",
                                    background:
                                      "linear-gradient(180deg, #0E397F 0%, #001E50 100%) !important",
                                    marginTop: "20px",
                                    padding: "10px 20px !important",
                                    textTransform: "capitalize !important",
                                  }}
                                  onClick={handleClose}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  variant="contained"
                                  className="all_button_clr"
                                  color="primary"
                                  sx={{
                                    fontSize: "16px !impotant",
                                    background: "#092b5f",
                                    marginTop: "20px",
                                    padding: "10px 20px !important",
                                    textTransform: "capitalize !important",
                                  }}
                                  onClick={handleSubmit}
                                >
                                  save
                                </Button>
                              </form>
                            </Box>
                          </Fade>
                        </Modal>
                        {/* -----   Add Campaigns Modal End   ----- */}
                      </div>
                    </div>
                  </div>

                  {/* 
             <Box>
             <div style={{ height: 400, width: '100%' }}>
     <DataGrid rows={rows} columns={columns}
     
       // pageSizeOptions={[5, 10]}
       // checkboxSelection
       headerClassName="custom-header"
     />
   </div>
             </Box> */}

                  {/* -----   Add Buyer Modal Start   ----- */}
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={buyerOpen}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}
                    borderRadius={"10px"}
                  >
                    <Fade in={buyerOpen}>
                      <Box sx={style} borderRadius={"10px"}>
                        <IconButton
                          onClick={handleAddBuyerClose}
                          sx={{ float: "inline-end", borderRadius: "10px;" }}
                        >
                          <Close />
                        </IconButton>
                        <br />
                        <Typography
                          id="transition-modal-title"
                          variant="h6"
                          component="h2"
                          color={"#092b5f"}
                          fontSize={"18px"}
                          fontWeight={"600"}
                          textAlign={"center"}
                        >
                          Add Buyer
                        </Typography>
                        <Typography
                          id="transition-modal-description"
                          sx={{ mt: 2 }}
                          fontSize={"16px"}
                          color={"#000"}
                          paddingBottom={"10px"}
                          textAlign={"center"}
                        >
                          {/* A ring group is a set of destinations that can be called with a ring strategy. */}
                        </Typography>
                        <form style={{ textAlign: "center" }}>
                          <TextField
                            style={{ width: "100%", margin: " 5px 0 5px 0" }}
                            type="text"
                            label="Name"
                            variant="outlined"
                          />
                          <br />
                          <TextField
                            style={{ width: "100%", margin: " 5px 0 5px 0" }}
                            type="text"
                            label="DID/TFN Number"
                            variant="outlined"
                          />
                          <br />
                          <TextField
                            style={{ width: "100%", margin: " 5px 0 5px 0" }}
                            type="text"
                            label="Weightage"
                            variant="outlined"
                          />
                          <br />
                          <TextField
                            style={{ width: "100%", margin: " 5px 0 5px 0" }}
                            type="text"
                            label="Ringing Timeout"
                            variant="outlined"
                          />
                          <br />
                          <TextField
                            style={{ width: "100%", margin: " 5px 0 5px 0" }}
                            type="text"
                            label="Max Calls In A Day"
                            variant="outlined"
                          />
                          <br />
                          <TextField
                            style={{ width: "100%", margin: " 5px 0 5px 0" }}
                            type="text"
                            label="No CC (Concurrent Call)"
                            variant="outlined"
                          />
                          <br />

                          <Button
                            variant="contained"
                            color="primary"
                            className="all_button_clr"
                            sx={{ background: "#092b5f", marginTop: "20px" }}
                          >
                            save
                          </Button>
                        </form>
                      </Box>
                    </Fade>
                  </Modal>
                  {/* -----   Add Buyer Modal End   ----- */}
                  {/* ----------------------------------------------
                     ----------------------------------------------
                     ----------------------------------------------
                     ---------------------------------------------- */}
                  {/* -----   Edit Campaign Modal Start   ----- */}
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={edit}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}
                  >
                    <Fade in={edit} className="bg_imagess">
                      <Box sx={style} borderRadius={"10px"}>
                        <IconButton
                          onClick={handleEditCampaignClose}
                          sx={{ float: "inline-end" }}
                        >
                          <Close />
                        </IconButton>
                        <br />
                        <Typography
                          id="transition-modal-title"
                          variant="h6"
                          component="h2"
                          color={"#092b5f"}
                          fontSize={"18px"}
                          fontWeight={"600"}
                          textAlign={"center"}
                        >
                          Update Campaign
                        </Typography>
                        <Typography
                          id="transition-modal-description"
                          sx={{ mt: 2 }}
                        ></Typography>
                        <form style={{ textAlign: "center" }}>
                          <TextField
                            style={{ width: "100%", margin: " 5px 0 5px 0" }}
                            type="text"
                            label="Campaign Name"
                            variant="outlined"
                            name="campaignName"
                            value={campaignName}
                            onChange={handleChange}
                          />
                          <br />
                          <TextField
                            style={{ width: "100%", margin: " 5px 0 5px 0" }}
                            type="text"
                            label="Description"
                            variant="outlined"
                            name="description"
                            value={description}
                            onChange={handleChange}
                          />
                          <br />

                          <Button
                            variant="contained"
                            className="all_button_clr mt-3"
                            color="primary"
                            onClick={handleUpdate}
                                                   >
                            Update
                          </Button>
                        </form>
                      </Box>
                    </Fade>
                  </Modal>
                  {/* -----   Edit Campaign Modal End   ----- */}
                </div>
              </div>
            </div>

            {/* ========== */}

            <ThemeProvider theme={theme}>
              <div style={{ height: "100%", width: "100%" }}>
                <DataGrid
                  rows={mockDataTeam}
                  columns={columns}
                   density="compact"
                  // getRowClassName={(params) =>
                  //   isRowBordered(params)
                  //     ? "borderedGreen"
                  //     : "borderedRed"
                  // }
                  components={{ Toolbar: GridToolbar }}
                  slots={{
                    toolbar: CustomToolbar,
                  }}
                  autoHeight // Automatically adjust the height to fit all rows
                />
              </div>
            </ThemeProvider>
          </Box>
        </div>
      </div>
    </>
  );
}

export default Manage_Campaign;
