export const GET_DID_REQUEST = "GET_DID_REQUEST";
export const GET_DID_SUCCESS = "GET_DID_SUCCESS";
export const GET_DID_FAIL = "GET_DID_FAIL";

export const GET_REDIRECT_GROUPS_REQUEST = "GET_REDIRECT_GROUPS_REQUEST";
export const GET_REDIRECT_GROUPS_SUCCESS = "GET_REDIRECT_GROUPS_SUCCESS";
export const GET_REDIRECT_GROUPS_FAIL = "GET_REDIRECT_GROUPS_FAIL";

export const CREATE_DESTINATION_REQUEST = "CREATE_DESTINATION_REQUEST";
export const CREATE_DESTINATION_SUCCESS = "CREATE_DESTINATION_SUCCESS";
export const CREATE_DESTINATION_FAIL = "CREATE_DESTINATION_FAIL";

export const UPDATE_DESTINATION_REQUEST = "UPDATE_DESTINATION_REQUEST";
export const UPDATE_DESTINATION_SUCCESS = "UPDATE_DESTINATION_SUCCESS";
export const UPDATE_DESTINATION_FAIL = "UPDATE_DESTINATION_FAIL";
