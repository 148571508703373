export const ALL_REDIRECT_CAMPAIGN_REQUEST = "ALL_REDIRECT_CAMPAIGN_REQUEST";
export const ALL_REDIRECT_CAMPAIGN_SUCCESS = "ALL_REDIRECT_CAMPAIGN_SUCCESS";
export const ALL_REDIRECT_CAMPAIGN_FAIL = "ALL_REDIRECT_CAMPAIGN_FAIL";

export const CREATE_REDIRECT_CAMPAIGN_REQUEST = "CREATE_REDIRECT_CAMPAIGN_REQUEST";
export const CREATE_REDIRECT_CAMPAIGN_SUCCESS = "CREATE_REDIRECT_CAMPAIGN_SUCCESS";
export const CREATE_REDIRECT_CAMPAIGN_FAIL = "CREATE_REDIRECT_CAMPAIGN_FAIL";

export const UPDATE_REDIRECT_CAMPAIGN_REQUEST = "UPDATE_REDIRECT_CAMPAIGN_REQUEST";
export const UPDATE_REDIRECT_CAMPAIGN_SUCCESS = "UPDATE_REDIRECT_CAMPAIGN_SUCCESS";
export const UPDATE_REDIRECT_CAMPAIGN_FAIL = "UPDATE_REDIRECT_CAMPAIGN_FAIL";

export const DELETE_REDIRECT_CAMPAIGN_REQUEST = "DELETE_REDIRECT_CAMPAIGN_REQUEST";
export const DELETE_REDIRECT_CAMPAIGN_SUCCESS = "DELETE_REDIRECT_CAMPAIGN_SUCCESS";
export const DELETE_REDIRECT_CAMPAIGN_FAIL = "DELETE_REDIRECT_CAMPAIGN_FAIL";