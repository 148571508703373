export const GET_ADMIN_ADD_BUYER_REQUEST = "GET_ADMIN_ADD_BUYER_REQUEST";
export const GET_ADMIN_ADD_BUYER_SUCCESS = "GET_ADMIN_ADD_BUYER_SUCCESS";
export const GET_ADMIN_ADD_BUYER_FAIL = "GET_ADMIN_ADD_BUYER_FAIL";

export const CREATE_ADMIN_ADD_BUYER_REQUEST = "CREATE_ADMIN_ADD_BUYER_REQUEST";
export const CREATE_ADMIN_ADD_BUYER_SUCCESS = "CREATE_ADMIN_ADD_BUYER_SUCCESS";
export const CREATE_ADMIN_ADD_BUYER_FAIL = "CREATE_ADMIN_ADD_BUYER_FAIL";

export const UPDATE_ADMIN_ADD_BUYER_REQUEST = "UPDATE_ADMIN_ADD_BUYER_REQUEST";
export const UPDATE_ADMIN_ADD_BUYER_SUCCESS = "UPDATE_ADMIN_ADD_BUYER_SUCCESS";
export const UPDATE_ADMIN_ADD_BUYER_FAIL = "UPDATE_ADMIN_ADD_BUYER_FAIL";

export const DELETE_ADMIN_ADD_BUYER_REQUEST = "DELETE_ADMIN_ADD_BUYER_REQUEST";
export const DELETE_ADMIN_ADD_BUYER_SUCCESS = "DELETE_ADMIN_ADD_BUYER_SUCCESS";
export const DELETE_ADMIN_ADD_BUYER_FAIL = "DELETE_ADMIN_ADD_BUYER_FAIL";