import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../../src/style.css";
import "../redirect_portal/redirect_style.css";
import { toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";
import Navbar from "./Navbar";
import { Dropdown } from "react-bootstrap";
import LiveCall from "../../pages/LiveCall";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import CallIcon from "@mui/icons-material/Call";

function Header() {
  const [selectedValue, setSelectedValue] = useState(''); // State to hold the selected value
  const current_user = localStorage.getItem("current_user");
    const user = JSON.parse(localStorage.getItem(`user_${current_user}`));
  const navigate = useNavigate();

  const handleSelect = (eventKey) => {
    // When a Dropdown.Item is clicked, update the selected value
    setSelectedValue(eventKey);
  
  };

  const logout = () => {

    // let data = JSON.stringify({
    //   "user_uuid": user?.user_uuid
    // });
    
    // let config = {
    //   method: 'post',
    //   maxBodyLength: Infinity,
    //   url: 'http://95.217.227.234:5000/logout',
    //   headers: { 
    //     'Content-Type': 'application/json'
    //   },
    //   data : data
    // };
    
    // axios.request(config)
    // .then((res) => {
    //   if (res?.data?.message !== "") {
         toast.info("Logout Successfully", {
         position: toast.POSITION.TOP_RIGHT,
          autoClose: 1500,
        });
        localStorage.removeItem("user");
        navigate("/");
    //   }
    // })
    // .catch((error) => {
    //   console.log(error);
    // });



    // axios
    //   .get("https://ikalyuga.com/api/index.php?action=logout", {})
    //   .then((res) => {
    //     if (res?.data?.response?.message !== "") {
    //       toast.info(res.data.response.message, {
    //         position: toast.POSITION.TOP_RIGHT,
    //         autoClose: 1500,
    //       });
    //       localStorage.removeItem("user");
    //       navigate("/");
    //     }
    //   });
  };

  useEffect(() => {
    
    if (selectedValue === 'Manage') {
      navigate('/manage');
    }
    
  }, [selectedValue]); // This effect runs whenever selectedValue changes



  return (
    <>
      <Box sx={{ flexGrow: 1 }} className="manage_boxx">
        <AppBar position="static" className="manage_top_header">
          <Toolbar>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
              className="d-flex align-items-center"
            >
              <a href="/redirect_portal">
              <img
                src="/img/logo_white11.png"
                alt="Manage Logo"
                className="img-fluid d-block"
                style={{cursor:"pointer",width:'85%'}}
              />
              </a>
            </Typography>

            <div className="manage_rgiht_bdr d-flex align-items-center">
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                className="manage_call"
              >
                <CallIcon className="call_icon" />
                <LiveCall />
                Live
                {/* <LiveCall /> */}
              </IconButton>
              <div className="dshbrd_hdr_icon">
                <ul>
                  <li>
                    <ul className="hdr_profile">
                      <li>
                        {/* Add a class to the image element */}
                        <img
                          src="/img/nav_author.jpg"
                          className="img-fluid d-block rounded-circle" // Apply rounded-circle class for circular display
                          alt="profile"
                        />
                        <div className="profile_name">
                          <b>{user?.user_name}</b>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>

              {user?.user_type === "admin" ? (<></>):(<>
                <Dropdown onSelect={handleSelect}>
                <Dropdown.Toggle
                  className="dropbtn"
                  id="dropdown-basic"
                  style={{
                    color: "#fff",
                    textTransform: "capitalize",
                    fontSize: "14px",
                  }}
                >
                  Services
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {selectedValue === "Manage" ?(<>
                  <Dropdown.Item  eventKey="Redirect">Redirect</Dropdown.Item>
                  <Dropdown.Item href="/sip" eventKey="Sip">Sip</Dropdown.Item></>):(<>
                  <Dropdown.Item  href="/manage_portal">Manage</Dropdown.Item>
                  <Dropdown.Item href="/sip_portal" eventKey="Sip">Sip</Dropdown.Item></>)}
                  
                  
                </Dropdown.Menu>
              </Dropdown></>)}
             

              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                style={{ paddingRight: "0" }}
                onClick={logout}
              >
                <LogoutIcon className="call_icon " />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {/* <!--navbar-sec--> */}
        <Navbar selectedValue={selectedValue}/>
      </Box>
    </>
  );
}

export default Header;
