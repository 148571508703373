import React, { useEffect, useState } from "react";
import "../../../src/style.css";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { createTheme, IconButton, Stack, TextField, Tooltip } from "@mui/material";
import { DataGrid, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton } from "@mui/x-data-grid";
import { Delete, Edit, PlayArrow, Close } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useLocation, useNavigate } from "react-router-dom";
import { getRedirectBuyer } from "../../redux/actions/redirectPortal/redirectPortal_buyerAction";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { GET_REDIRECT_BUYER_SUCCESS } from "../../redux/constants/redirectPortal/redirectPortal_buyerConstants";
import {ThemeProvider } from "@mui/styles";

// ============>

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 52,
  height: 31,
  padding: 0,
  borderRadius: "33px",
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 27,
    height: 27,
    // right: '0px',
    // position: 'relative',
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          "& .MuiDataGrid-row": {
            minHeight: "auto", // Adjust row height to make it more compact
          },
        },
      },
      defaultProps: {
        density: "compact", // Set default density to compact
      },
    },
  },
});

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      <GridToolbarFilterButton />
      {/* <GridToolbarExport /> */}
    </GridToolbarContainer>
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function BuyerView() {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [buyerOpen, setBuyerOpen] = useState(false);
  const handleAddBuyerOpen = () => setBuyerOpen(true);
  const handleAddBuyerClose = () => setBuyerOpen(false);
  console.log(state?.getRedirectBuyer?.RedirectBuyer);

  useEffect(() => {
    dispatch(getRedirectBuyer(location.state.data.campaignId));
  }, [location.state.data.campaignId]);

  const handleEdit = () =>{

  }

  const handleDelete = () =>{
    
  }

  const columns = [
    {
      field: "action",
      headerName: "Action",
      width: 100,
      headerClassName: "custom-header",
      headerAlign: "center",
      align: "center",
      sortable: false,
      width: 140,
      renderCell: (params) => {
        return (
          <div className="d-flex justify-content-between align-items-center">
            <Tooltip title="edit" disableInteractive interactive>
            <IconButton onClick={() => handleEdit(params.row)}>
              <Edit
                index={params.row.id}
                style={{ cursor: "pointer", color: "#42765f" }}
              />
            </IconButton>
            </Tooltip>
            <Tooltip title="delete" disableInteractive interactive>
            <IconButton onClick={() => handleDelete(params.row)}>
              <Delete style={{ cursor: "pointer", color: "red" }} />
            </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "buyer_name",
      headerName: "Buyer Name",
      headerClassName: "custom-header",
      headerAlign: "center",
      width: 150,
      align: "center",
      renderCell: (params) => {
        const formattedDate = dayjs(params.row.createdAt).format(
          "DD MMM, YYYY"
        );
        return (
          <>
            <p style={{ margin: "0", lineHeight: "14px" }}>
              {params.row.buyer_name}
              <br />
              <i style={{ margin: "0", fontSize: "11px" }}>
                {" "}
                <b>Created: {formattedDate}</b>
              </i>
            </p>
          </>
        );
      },
    },
    // {
    //   field: "group_name",
    //   headerName: "Group Name",
    //   headerClassName: "custom-header",
    //   headerAlign: "center",
    //   width: 140,
    //   align: "center",
    // },
    {
      field: "username",
      headerName: "User Name",
      width: 100,
      headerClassName: "custom-header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      headerAlign: "center",
      align: "center",
      headerClassName: "custom-header",
      renderCell: (params) => {
        return (
          <>
            {params.row.status === true ? (
              <>
                <div
                  className="d-flex justify-content-between align-items-center"
                  style={{
                    color: "green",
                    border: "1px solid green",
                    padding: "5px 4.5px",
                    borderRadius: "5px",
                  }}
                >
                  Active
                </div>
              </>
            ) : (
              <>
                <div
                  className="d-flex justify-content-between align-items-center"
                  style={{
                    color: "red",
                    border: "1px solid red",
                    padding: "5px 4.5px",
                    borderRadius: "5px",
                  }}
                >
                  Deactive
                </div>
              </>
            )}
          </>
        );
      },
    },
    {
      field: "forward_number",
      headerName: "Forward Number",
      headerClassName: "custom-header",
      headerAlign: "center",
      width: 140,
      align: "center",
    },
    {
      field: "cc",
      headerName: "CC",
      headerClassName: "custom-header",
      headerAlign: "center",
      width: 50,
      align: "center",
    },
    {
      field: "current_cc",
      headerName: "Current CC",
      headerClassName: "custom-header",
      headerAlign: "center",
      width: 100,
      align: "center",
    },
    {
      field: "current_daily_limit",
      headerName: "Current Daily Limit",
      headerClassName: "custom-header",
      headerAlign: "center",
      width: 150,
      align: "center",
    },
    {
      field: "daily_limit",
      headerName: "Daily Limit",
      headerClassName: "custom-header",
      headerAlign: "center",
      width: 100,
      align: "center",
    },
    {
      field: "weightage",
      headerName: "Weightage",
      headerClassName: "custom-header",
      headerAlign: "center",
      width: 100,
      align: "center",
    },

    {
      field: "working_start_time",
      headerName: "Start Time",
      headerClassName: "custom-header",
      headerAlign: "center",
      width: 90,
      align: "center",
    },
    {
      field: "working_end_time",
      headerName: "End Time",
      width: 90,
      headerClassName: "custom-header",
      headerAlign: "center",
      align: "center",
    },

  
   
  ];

  const mockDataTeam = [];
  state?.getRedirectBuyer?.RedirectBuyer?.data &&
    state?.getRedirectBuyer?.RedirectBuyer?.data?.forEach((item, index) => {
      mockDataTeam.push({
        id: index + 1,
        cc: item.cc,
        group_name: item.group_name,
        created_at: item.created_at,
        current_cc: item.current_cc,
        current_daily_limit: item.current_daily_limit,
        daily_limit: item.daily_limit,
        follow_working_time: item.follow_working_time,
        forward_number: item.forward_number,
        buyer_name: item.buyer_name,
        buyer_id: item.id,
        redirect_group_id: item.redirect_group_id,
        status: item.status,
        username: item.username,
        weightage: item.weightage,
        working_end_time: item.working_end_time,
        working_start_time: item.working_start_time,
      });
    });
  return (
    <>
      <div className="main">
        <section className="sidebar-sec">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="">
                  {/* <!----> */}
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      {/* <!--role-contet--> */}
                      <div className="tab_cntnt_box">
                        {/* <div className="cntnt_title"> */}
                        <div
                          className="cntnt_title"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <h3 style={{ margin: "0px" }}>Buyer View</h3>

                          <FormGroup
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginRight: "30px",
                            }}
                          >
                            <Stack
                              direction="row"
                              spacing={1}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography style={{ fontSize: "15px" }}>
                                All
                              </Typography>
                              <FormControlLabel
                                control={<IOSSwitch defaultChecked />}
                              />
                              <Typography style={{ fontSize: "15px" }}>
                                Active
                              </Typography>
                            </Stack>
                          </FormGroup>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "end",
                            marginBottom: "40px",
                          }}
                        >
                          <p style={{ fontSize: "17px", color: "#000" }}>
                          <b className="fnt_bld"> Campaign Name:</b>{" "}
                          {location.state.data.group_name}
                          </p>

                          <div>
                            <IconButton
                              style={{
                                padding: "10px",
                                fontSize: "15px",
                                borderRadius: "5px",
                                border: "none",
                                //backgroundColor: "rgb(9, 56, 134)",
                                color: "#fff",
                                marginLeft: "auto",
                                marginRight: "30px",
                              }}
                              className="redirect_all_button_clr"
                              onClick={() =>
                               { dispatch({
                                  type: GET_REDIRECT_BUYER_SUCCESS,
                                  payload: [],
                                })
                                navigate("/redirect_portal/campaigns")
                              }
                              }
                            >
                              <ArrowBackIcon style={{ fontSize: "24px" }} />
                              {/* Back */}
                            </IconButton>

                            <IconButton
                              style={{
                                padding: "10px",
                                fontSize: "15px",
                                borderRadius: "5px",
                                border: "none",
                                //backgroundColor: "rgb(9, 56, 134)",
                                color: "#fff",
                                marginLeft: "auto",
                                marginRight: "30px",
                              }}
                              className="redirect_all_button_clr"
                              onClick={handleAddBuyerOpen}
                            >
                              Add Buyer
                              <AddOutlinedIcon />
                            </IconButton>
                          </div>
                        </div>
                        {/* </div> */}
                        {/* -----   Add Buyer Modal Start   ----- */}

                        <Modal
                          aria-labelledby="transition-modal-title"
                          aria-describedby="transition-modal-description"
                          open={buyerOpen}
                          closeAfterTransition
                          slots={{ backdrop: Backdrop }}
                          slotProps={{
                            backdrop: {
                              timeout: 500,
                            },
                          }}
                        >
                          <Fade in={buyerOpen}>
                            <Box
                              sx={style}
                              borderRadius={"10px"}
                              textAlign={"center"}
                            >
                              <IconButton
                                onClick={handleAddBuyerClose}
                                sx={{ float: "inline-end" }}
                              >
                                <Close />
                              </IconButton>
                              <br />
                              <Typography
                                id="transition-modal-title"
                                variant="h6"
                                component="h2"
                                color={"#092b5f"}
                                fontSize={"18px"}
                                fontWeight={"600"}
                              >
                                Add Buyer
                              </Typography>
                              <Typography
                                id="transition-modal-description"
                                sx={{ mt: 2 }}
                                fontSize={"16px"}
                                color={"#000"}
                                paddingBottom={"10px"}
                              >
                                {/* A ring group is a set of destinations that can
                                be called with a ring strategy. */}
                              </Typography>
                              <form style={{ textAlign: "center" }}>
                                <TextField
                                  style={{
                                    width: "100%",
                                    margin: " 5px 0 5px 0",
                                  }}
                                  type="text"
                                  label="Name"
                                  variant="outlined"
                                />
                                <br />
                                <TextField
                                  style={{
                                    width: "100%",
                                    margin: " 5px 0 5px 0",
                                  }}
                                  type="text"
                                  label="DID/TFN Number"
                                  variant="outlined"
                                />
                                <br />
                                <TextField
                                  style={{
                                    width: "100%",
                                    margin: " 5px 0 5px 0",
                                  }}
                                  type="text"
                                  label="Weightage"
                                  variant="outlined"
                                />
                                <br />
                                <TextField
                                  style={{
                                    width: "100%",
                                    margin: " 5px 0 5px 0",
                                  }}
                                  type="text"
                                  label="Ringing Timeout"
                                  variant="outlined"
                                />
                                <br />
                                <TextField
                                  style={{
                                    width: "100%",
                                    margin: " 5px 0 5px 0",
                                  }}
                                  type="text"
                                  label="Max Calls In A Day"
                                  variant="outlined"
                                />
                                <br />
                                <TextField
                                  style={{
                                    width: "100%",
                                    margin: " 5px 0 5px 0",
                                  }}
                                  type="text"
                                  label="No CC (Concurrent Call)"
                                  variant="outlined"
                                />
                                <br />

                                <Button
                                  variant="contained"
                                  color="primary"
                                  sx={{
                                    background: "#092b5f",
                                    marginTop: "20px",
                                  }}
                                >
                                  save
                                </Button>
                              </form>
                            </Box>
                          </Fade>
                        </Modal>
                        {/* -----   Add Buyer Modal End   ----- */}
                        <ThemeProvider theme={theme}>
                          <div style={{ height: "100%", width: "100%" }}>
                            <DataGrid
                            className="custom_header_redirect"
                              rows={mockDataTeam}
                              columns={columns}
                              density="compact"
                              // getRowClassName={(params) =>
                              //   isRowBordered(params)
                              //     ? "borderedGreen"
                              //     : "borderedRed"
                              // }
                              components={{ Toolbar: GridToolbar }}
                              slots={{
                                toolbar: CustomToolbar,
                              }}
                              autoHeight // Automatically adjust the height to fit all rows
                            />
                          </div>
                        </ThemeProvider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default BuyerView;
