import React from 'react'
import "../../../src/style.css";
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
function CallActive(){
  const columns = [
    {
      field: "id",
      headerName: "Call Number",
      headerClassName: "redirect_custom-header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "did_number",
      headerName: "Toll Free Number",
      flex: 1,
      //cellClassName: "name-column--cell",
      //headerClassName: 'super-app-theme--header'
      headerClassName: "redirect_custom-header",
      // editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "forwarding_number",
      headerName: "Forwording Number",
      type: "number",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "redirect_custom-header",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerClassName: "redirect_custom-header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
            <div className="d-flex justify-content-between align-items-center" >
              {params.row.status === "Ok" ?(<><div style={{ color: "green"}}>{params.row.status}</div></>):(<>
                <div style={{ color: "red"}}>{params.row.status}</div></>)}
                
             </div>
        );
     }
    },
 
    {
      field: "recording",
      headerName: "Call Duration",
      flex: 1,
      headerClassName: "redirect_custom-header",
      headerAlign: "center",
      align: "center",
    },
  ];

  const mockDataTeam = [
    {
      id: 1,
      did_number: "Jon Snow",
      forwarding_number: "jonsnow@gmail.com",
      campaign_number: 35,
      buyer_name: "(665)121-5454",
      date: "admin",
      time: "09:12pm",
      duration: "2 HH",
      status: "Ok",
      hangup_cause: "1",
      recording: "20 min",
    },
    {
      id: 2,
      did_number: "Cersei Lannister",
      forwarding_number: "cerseilannister@gmail.com",
      campaign_number: 42,
      buyer_name: "(421)314-2288",
      date: "manager",
      time: "09:12pm",
      duration: "2 HH",
      status: "Ok",
      hangup_cause: "1",
      recording: "20 min",
    },
    {
      id: 3,
      did_number: "Jaime Lannister",
      forwarding_number: "jaimelannister@gmail.com",
      campaign_number: 45,
      buyer_name: "(422)982-6739",
      date: "user",
      time: "09:12pm",
      duration: "2 HH",
      status: "No",
      hangup_cause: "1",
      recording: "20 min",
    },
    {
      id: 4,
      did_number: "Anya Stark",
      forwarding_number: "anyastark@gmail.com",
      campaign_number: 16,
      buyer_name: "(921)425-6742",
      date: "admin",
      time: "09:12pm",
      duration: "2 HH",
      status: "Ok",
      hangup_cause: "1",
      recording: "20 min",
    },
    {
      id: 5,
      did_number: "Daenerys Targaryen",
      forwarding_number: "daenerystargaryen@gmail.com",
      campaign_number: 31,
      buyer_name: "(421)445-1189",
      date: "user",
      time: "09:12pm",
      duration: "2 HH",
      status: "Ok",
      hangup_cause: "1",
      recording: "20 min",
    },
  
  ];
return (<>
<div className="main">
<section className="sidebar-sec">
  <div className="container-fluid">
    <div className="row">
      <div className="col-lg-12">
        <div className="">
          {/* <!----> */}
          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
              {/* <!--role-contet--> */}
              <div className="tab_cntnt_box">
                <div className="cntnt_title">
                  <h3>Active Calls</h3>
                  <p>Use this to monitor and interact with the active calls.</p>
                </div>
                <div className="row">
                <Box m="0px 0 0 0" height="75vh">
                            <DataGrid
                             // checkboxSelection
                              rows={mockDataTeam}
                              columns={columns}
                             
                              headerClassName="redirect_custom-header"
                            />
                          </Box>
                </div>
              </div>
            </div>


            {/* <!----> */}
{/* 
            <!----> */}
          </div>
          {/* <!----> */}


        </div>
      </div>
    </div>
  </div>
</section>
</div></>)
}

export default CallActive;